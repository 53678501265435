<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{ $t("message.telegramBotSetting") }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="24">
                       <el-form-item prop="name" :label="$t('message.name')">
                            <el-input
                                :placeholder="$t('message.name')"
                                v-model="form.name"
                                size="medium"
                            ></el-input>
                       </el-form-item>     
                    </el-col>

                    <el-col :span="24">
                       <el-form-item prop="filial_id" :label="$t('message.filial')">
                          <select-filial
                            :size="'medium'"
                            :placeholder="$t('message.filial')"
                            :id="form.filial_id"
                            v-model="form.filial_id"
                          >
                          </select-filial>
                       </el-form-item>     
                    </el-col>

                    <el-col :span="24">
                       <el-form-item prop="token" :label="$t('message.token')">
                            <el-input
                                :placeholder="$t('message.token')"
                                v-model="form.token"
                                size="medium"
                            ></el-input>
                       </el-form-item>     
                    </el-col>

                    <el-col :span="24">
                       <el-form-item prop="comment" :label="$t('message.comment')">
                            <el-input
                                autosize
                                type="textarea"
                                :autosize="{ minRows: 2 }"
                                :placeholder="$t('message.comment')"
                                v-model="form.comment"
                                size="medium"
                            ></el-input>
                       </el-form-item>     
                    </el-col>
                </el-row>
                
                <el-row :gutter="20">
                  <el-form-item prop="start_message" :label="$t('message.greeting_message')">
                    <el-upload
                        class="avatar-uploader"
                        :show-file-list="false"
                        action="/"
                        :on-change="updateImageList"
                        list-type="picture-card"
                        accept="image/jpeg, image/png"
                        :auto-upload="false"
                      >
                        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                        <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <el-input
                        type="textarea"
                        :placeholder="$t('message.greeting_message')"
                        v-model="form.start_message"
                        >
                    </el-input>
                  </el-form-item>     
                </el-row>

            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectFilial from "@/components/inventory/select-filial";

export default {
  
  mixins: [form, drawer],
  components: {
    selectFilial
  },
  data() {
    return {
			imageUrl: "",
    };
  },
  
  created() {},
  computed: {
      ...mapGetters({
          rules: "telegramBotSetting/rules",
          model: "telegramBotSetting/model",
          columns: "telegramBotSetting/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "telegramBotSetting/store",
      }),
      updateImageList(file) {
        if(file.size < 5200000){
          this.imageUrl = URL.createObjectURL(file.raw);
          this.updateImage = [];
          this.updateImage.push(file.raw);
        }
        else {
          this.$message({
              type: "warning",
              message: "Размер изображения должен быть меньше 5 МБ"
            });
        }
      },

      submit(close = true) {
          let formData = new FormData();

          for (const key in this.form) {
            if (key != "start_image") {
              if (this.form[key]) {
                formData.append(key, this.form[key]);
              } else {
                formData.append(key, "");
              }
            }else{
              formData.append(key, JSON.stringify(this.form[key]));
            }
          }

          if (_.size(this.updateImage) > 0) {
            for (const key in this.updateImage) {
              if (this.updateImage.hasOwnProperty(key)) {
                const element = this.updateImage[key];
                formData.append("start_image", element);
              }
            }
          }
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(formData)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>
